import React, {useState} from "react";
import {Link} from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import ScrollContainer from "react-indiana-drag-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Layout from "../components/layout";
import Seo from "../components/seo"

const Portfolio = ({location}) => {

  const breakpoints = useBreakpoint();
  const [activeCategory, setActiveCategory] = useState('All');

  const categories = [
    'All',
    'Custom Development',
    'Integrations',
    'Logo & Identity Design',
    'UI/UX Services',
    'Website Design',
    'Zendesk'
  ];
  
  const data = useStaticQuery(graphql`
    query {
      allProjectsJson {
        nodes {
          title
          categories
          link
          src {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  
  const CategoryFilter = () => (
    <ButtonGroup style={{ width: 'max-content' }} className="d-block text-center" aria-label="Portfolio's categories">
      {
        categories.map((category, index) => (
          <Button className={activeCategory === category ? 'active mb-lg-3 mr-3' : 'mb-lg-3 mr-3' } variant="secondary-light" onClick={() => { setActiveCategory(category) }}>{category}</Button>
        ))
      }
    </ButtonGroup>
  );

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Portfolio"
        description="View our work, which spans custom software, websites, mobile apps, custom integrations, user experience, visual identities and graphic design."
      />
      <div className="container pt-5">
        <div className="row">
          <div className="col">
            <h1>Our Awesome Work</h1>
            <p>            
              We love our clients – and we’re proud of the work we’ve done for them. Take a look. Browse the details. Then reach out when you’re ready to talk about what we can do for you.
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col d-flex align-items-center">
            { breakpoints.lg ? <CategoryFilter/> : 
              <> 
                <FontAwesomeIcon className="d-lg-none mr-3 text-primary" icon={faAngleLeft} />
                <ScrollContainer>
                  <CategoryFilter/>
                </ScrollContainer >
                <FontAwesomeIcon className="d-lg-none ml-3 text-primary" icon={faAngleRight} />
              </>
            }
          </div>
        </div>
        <div className="row">
          <div className="col d-flex flex-wrap portfolio-feed-full">
            {
              data.allProjectsJson.nodes.map((project, index) => {
                if(project.categories.split(",").map((item) => item.trim()).indexOf(activeCategory) > -1 || activeCategory === 'All') {
                  return(
                    <div className="portfolio-item mb-4">
                      <div className="card linked h-100">
                        <Link className="d-flex flex-column h-100" to={project.link}>
                          <div className="card-body flex-grow-1 flex-fill pb-6">
                            <h2>{project.title}</h2>
                            <p className="text-dark">{project.categories}</p>
                          </div>
                          <div className="card-footer bg-transparent border-0 p-0  flex-shrink-0">
                            <GatsbyImage
                              alt={project.title}
                              image={getImage(project.src.childImageSharp.gatsbyImageData)}
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  )
                }
                return null;
              })
            }
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Portfolio;
